import React, { useState, useEffect } from 'react';
import { getUrl } from 'Router/urlConfig';
import pdfGenerator from 'components/FamilyInfoPDF';
import SelfSpinner from 'components/SelfSpinner';
import { format, differenceInDays } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import xIcon from 'assets/img/icono_x.png';
import okIcon from 'assets/img/icono_ok.png';

import {
  Card,
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import api from 'Services/api';
import style from './styles.module.css';

function FamilyInfo() {
  const [report, setReport] = useState([]);
  const [reportDate, setReportDate] = useState();
  const appURL = getUrl('app');
  const tokenLocal = getDataFromLocalStorage('vacanted');

  useEffect(() => {
    async function getReport() {
      const reportApi = await api.get('/collection/type/familyReport', {
        headers: {
          authorization: tokenLocal.token,
        },
      });
      setReport(reportApi.data);
      setReportDate(reportApi.data[0]
        ? format(new Date(reportApi.data[0].savedAt), "dd 'de' MMMM, yyyy - HH:mm:ss", { locale: es })
        : 'Fecha del Informe de Familia');
    }

    getReport();
  }, []);

  const generatePDF = () => {
    if (report.length > 0) pdfGenerator(report);
  };

  function calculateTerm() {
    const lastUpdate = new Date(report[0].savedAt);
    return differenceInDays(new Date(), lastUpdate);
  }

  function renderTerm() {
    if (!report || !report[0] || !report[0].savedAt) return <p />;
    return <p>{`${calculateTerm()} días`}</p>;
  }

  function renderStatusIcon() {
    if (!report || !report[0] || !report[0].savedAt) return <p />;
    return calculateTerm() <= 90
      ? <img src={okIcon} alt="Icono de plazo vigente ok" className={style.icon} />
      : <img src={xIcon} alt="Icono de plazo vencido" className={style.icon} />;
  }

  if (!report) return <div className={style.spinner__container}><SelfSpinner /></div>;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 className="title">Informe de familia</h3>
            <p className="category mb-5">
              Completando este informe podrás postular a las instituciones educativas de tu interés.
            </p>
            <Card className="card-table card-table-show-mobile">
              <Card.Body className={style['card-area']}>
                <div className={`${style.version}`}>
                  <div className={style['title-area']}>
                    <h3>Última versión de Informe de Familia guardado</h3>
                    <div className={style.date__container}>
                      <h4>Fecha de última modificación:</h4>
                      <p>{reportDate}</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={`${style.status}`}>
                  <div>
                    <h3>Vigencia</h3>
                    {renderTerm()}
                    <p>El Informe caduca después de 90 días de la última actualización</p>
                    <span>
                      {renderStatusIcon()}
                    </span>
                  </div>
                  <div>
                    <h3>Completado</h3>
                    {report[0] ? <p>100%</p> : <p />}
                    <p>El Informe debe estar completado 100%</p>
                    <span>
                      {report[0] ? <img src={okIcon} alt="Icono de informe completado" className={style.icon} /> : <p />}
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className={style['aside-card-area']}>
              <div>
                <p className={style['info-title']}>{report?.length === 0 ? 'Aún no has creado ningún Informe de Familia' : ''}</p>
                <Button
                  className={style['card-button']}
                  as="button"
                  type="reset"
                  onClick={() => window.location.replace(`${appURL}/family-infos`)}
                >
                  {report.length === 0 ? 'Crear Informe de Familia' : 'Editar Informe de Familia'}
                </Button>
                {' '}
              </div>
              <div className={style['download-area']}>
                <Button
                  className={style['card-button']}
                  as="button"
                  type="reset"
                  onClick={() => generatePDF()}
                  disabled={report.length < 1}
                >
                  Descargar Última versión
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FamilyInfo;
