import axios from 'axios';
import { getUrl } from 'Router/urlConfig';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';

export default async function getFavorites() {
  const apiUrl = getUrl('api');
  const { token } = getDataFromLocalStorage('vacanted');

  return axios.get(`${apiUrl}/collection/type/favorite?include_affinity=true`, {
    headers: {
      authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error);
}
