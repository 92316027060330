import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { format } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import pdfPostulation from 'components/PostulationPDF';
import api from 'Services/api';
import style from './styles.module.css';

export default function AdmissionsSent() {
  const tokenLocal = getDataFromLocalStorage('vacanted');
  const [postulates, setPostulates] = useState([]);

  useEffect(() => {
    async function getReport() {
      const postulatesApi = await api.get('/postulate/getPostulationUser', {
        headers: {
          authorization: tokenLocal.token,
        },
      });
      setPostulates(postulatesApi.data);
    }

    getReport();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 className="title">Postulaciones enviadas</h3>
            <p className="category mb-5">
              En esta sección se muestra un listado de las postulaciones realizadas por
              el usuario en cada centro educativo.
              <br />

            </p>
            <Card className="card-table card-table-show-mobile">
              <Card.Body className={style['card-area']}>
                <div className={`${style.version}`}>
                  <div className={style['title-area']}>
                    <h3>Postulaciones guardadas</h3>
                  </div>
                  <div />
                </div>
                <hr />

                <div className={`${style.status}`}>
                  <div>
                    <p className={style.negrito}>Nombre del Centro</p>
                    <p className={style.negrito}>Fecha de Postulación</p>
                    <p className={style.negrito}>Estado de la postulación</p>
                    <p className={style.negrito}>Descargar</p>
                  </div>
                  {postulates.length !== 0
                    ? postulates.map((postulate) => (
                      <div>
                        <p className={style.table_item}>{postulate.school.name}</p>
                        <p className={style.table_item}>
                          {format(new Date(postulate?.updatedAt), "'Día' dd 'de' MMMM' a las ' HH:mm'h'", { locale: es })}
                        </p>
                        {
                          postulate.status
                            ? (
                              <p className={postulate?.postulation_status.id === 3 && style.denied}>
                                {postulate?.postulation_status.name}
                              </p>
                            )
                            : (
                              <p />
                            )

                        }

                        <p className={style.button}><button onClick={() => pdfPostulation(postulate)} type="button" className={style.button}>Descargar</button></p>
                      </div>
                    )) : <h1>No hay postulaciones creadas</h1>}

                </div>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}
