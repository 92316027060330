import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import CannotPostulateModal from 'components/Modals/cannotPostulateModal';
import { getUrl } from 'Router/urlConfig';
import { savePostulationAlert } from 'Services/api';

import '../../assets/css/table.css';

function SchoolsTable({ data, moreFeatures }) {
  const appURL = getUrl('app');
  const [
    isCannotPostulateModalOpen, setIsCannotPostulateModalOpen,
  ] = useState({ status: false, school: {} });

  /* const handleModal = (school) => {
    console.log('SCHOOL', school);
    if (!school.postulate) {
      setIsCannotPostulateModalOpen({ status: !isCannotPostulateModalOpen.status, school });
    } else {
      window.open(`${appURL}/school/${school.id}`, '_blank');
    }
  }; */

  const handleModal = (school) => {
    window.open(`${appURL}/school/${school.id}`, '_blank');
  };

  const handlePostulation = async (check, school) => {
    if (school) {
      const { id, postulate } = school;
      if (!postulate) {
        const alert = check;
        const postulationAlert = {
          school_id: id,
          postulated: false,
          alert,
        };

        await savePostulationAlert(postulationAlert);
      }
    }

    setIsCannotPostulateModalOpen((current) => ({ ...current, status: false }));
  };

  return (
    <div className="ReactTable -striped -highlight primary-pagination">
      {moreFeatures ? moreFeatures.map((Component) => <div key={moreFeatures.key}>{Component}</div>) : ''}
      <Table responsive>
        <tbody>
          {data.map((school, index) => (
            <tr key={school.id} className={index % 2 === 0 ? '_odd' : '_even'}>
              <td>{school.name}</td>
              <td>{`${school.public}`}</td>
              <td>{`Distancia ${school.distance} Km`}</td>
              <td style={{ color: !school.schoolAffinity ? '#cecece' : '#212529' }}>
                {`Afinidad ${school.schoolAffinity ? school.schoolAffinity.totalAffinity : '-'}%`}
              </td>
              <td>
                <div className="actions-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleModal(school)}
                  >
                    VER INFO O CONTACTAR
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CannotPostulateModal
        isOpen={isCannotPostulateModalOpen.status}
        school={isCannotPostulateModalOpen.school}
        onRequestClose={(check, school) => handlePostulation(check, school)}
      />
    </div>
  );
}

export default SchoolsTable;

SchoolsTable.propTypes = {
  columns: PropTypes.arrayOf([PropTypes.any]),
  data: PropTypes.arrayOf([PropTypes.any]),
  moreFeatures: PropTypes.arrayOf([PropTypes.any]),
}.isRequired;
