export const getDataFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);

  return JSON.parse(value);
};

export function saveDataInLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function removeDataInLocalStorage(key) {
  localStorage.removeItem(key);
}
