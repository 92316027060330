import axios from 'axios';
import { getUrl } from 'Router/urlConfig';
import { getDataFromLocalStorage, removeDataInLocalStorage } from 'dist/Storage/localStorageMethods';

export default async function logout() {
  const apiURL = getUrl('api');
  const appURL = getUrl('app');
  const { token } = await getDataFromLocalStorage('vacanted');
  const body = { token };

  return axios.post(`${apiURL}/logout`, body, {
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((resp) => (resp.status === 201 ? removeDataInLocalStorage('vacanted') : ''))
    .then(() => window.location.replace(appURL))
    .catch((error) => error);
}
