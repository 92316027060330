import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

function SortBy({ getComponent, setComponent }) {
  const sortArr = [
    'Relevancia',
    'Distancia',
    // 'Mayor Precio',
    // 'Menor Precio',
    'Nombre Ascendente',
    'Nombre Descendente',
    'Reviews de Google',
  ];

  const rearrange = {
    distance() {
      if (!getComponent) return [];
      function compare(a, b) {
        if (a.distance < b.distance) {
          return -1;
        }
        if (a.distance > b.distance) {
          return 1;
        }
        return 0;
      }

      const organizedValuesOfTheOldest = Object.values(getComponent).sort(compare);

      organizedValuesOfTheOldest.map((position) => position);
      return setComponent(organizedValuesOfTheOldest);
    },
    nameAsc() {
      if (!getComponent) return [];
      function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }

      const organizedValuesOfTheOldest = Object.values(getComponent).sort(compare);

      organizedValuesOfTheOldest.map((position) => position);
      return setComponent(organizedValuesOfTheOldest);
    },
    nameDesc() {
      if (!getComponent) return [];
      function compare(a, b) {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      }

      const organizedValuesOfTheOldest = Object.values(getComponent).sort(compare);

      organizedValuesOfTheOldest.map((position) => position);
      return setComponent(organizedValuesOfTheOldest);
    },
    googleReview() {
      if (!getComponent) return [];
      function compare(a, b) {
        const aRating = typeof a.rating === 'string' ? parseFloat(a.rating) : a.rating;
        const bRating = typeof b.rating === 'string' ? parseFloat(a.rating) : a.rating;
        if (typeof aRating === 'number' && typeof bRating === 'number' && aRating >= 0 && bRating >= 0) {
          if (a.rating > b.rating) {
            return -1;
          }
          if (a.rating < b.rating) {
            return 1;
          }
        }
        return 0;
      }

      const organizedValuesOfTheOldest = Object.values(getComponent).sort(compare);

      organizedValuesOfTheOldest.map((position) => position);
      return setComponent(organizedValuesOfTheOldest);
    },
  };

  function sortgetComponent(option) {
    switch (option.value) {
      case 'Relevancia':
        rearrange.distance();
        break;
      case 'Nombre Ascendente':
        rearrange.nameAsc();
        break;
      case 'Nombre Descendente':
        rearrange.nameDesc();
        break;
      case 'Reviews de Google':
        rearrange.googleReview();
        break;
      default:
        rearrange.distance();
        break;
    }
  }

  return (
    <div className="sort-area">
      <div className="sort-container">
        <span>Ordenar por </span>
        <Select
          className="sort-area-select"
          placeholder={sortArr[0]}
          onChange={(value) => sortgetComponent(value)}
          options={sortArr.map((prop) => ({
            value: prop,
            label: `${prop}`,
          }))}
        />
      </div>
    </div>
  );
}

export default SortBy;

SortBy.propTypes = {
  getComponent: PropTypes.object,
  setComponent: PropTypes.func,
}.isRequired;
