import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/light-bootstrap-dashboard-pro-react.scss';
import FamilyLayout from 'layouts/Family';
import Blank from 'components/Blank';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/family" component={FamilyLayout} />
        <Route exact path="/:token/:instruction?" component={Blank} />
      </Switch>
    </BrowserRouter>
  );
}
