import React from 'react';
import Select from 'react-select';
import {
  Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import formatDates from 'dist/FormatDates/formatDates';
import moment from 'moment';

import style from './styles.module.css';
import './DropDownSelect.css';

function DropDownSelect({
  pageSelect,
  setPageSelect,
  filters,
}) {
  function fillFilter() {
    if (!filters) return [];
    function compare(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    }

    const organizedValuesOfTheOldest = Object.values(filters).sort(compare);

    return organizedValuesOfTheOldest.map((position) => position);
  }

  function setLabel(property) {
    function chooseLabel() {
      const splitName = property.name.split(' - ');
      const date = moment(splitName[1], 'DD-MM-YYYY');

      if (date.isValid()) {
        return `Test de Compatibilidad realizado ${property.savedAt
          ? `${(formatDates(property.savedAt, 'es').dateFormat)} 
          - ${formatDates(property.savedAt, 'es').hourFormat}`
          : ''}`;
      }

      return `${property.name} ${property.savedAt
        ? `${(formatDates(property.savedAt, 'es').dateFormat)} 
        - ${formatDates(property.savedAt, 'es').hourFormat}`
        : ''}`;
    }

    const infos = {
      value: property.name,
      label: chooseLabel(),
    };

    return infos;
  }

  return (
    <Col className={style.dropDownHeader}>
      <Select
        className={`${style['react-select']} ${style.primary}`}
        classNamePrefix="react-select"
        name="pageSelect"
        value={pageSelect}
        onChange={(value) => setPageSelect(value)}
        options={fillFilter().map((prop) => (setLabel(prop)))}
      />
    </Col>
  );
}

export default DropDownSelect;

DropDownSelect.propTypes = {
  pageSelect: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  setPageSelect: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
}.isRequired;
