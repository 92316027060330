import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const pdfGenerator = (objectInfo) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const familyInfo = objectInfo[0];

  const report = {
    email: familyInfo.name.split(' - ')[0].trim(),
    lastUpdate: familyInfo.savedAt.split('GMT')[0].trim(),
    parent: familyInfo.familyReport['1'].parent.name,
    parentDescription: familyInfo.familyReport['1'].description,
    familyPresentation: familyInfo.familyReport['2'],
    familyObjective: familyInfo.familyReport['3'],
    familyRequirements: familyInfo.familyReport['4'],
    city: familyInfo.familyReport['5'].name,
    address: familyInfo.familyReport['5'].address,
    familyComposition: familyInfo.familyReport['6'].map((comp, index) => [
      `integrante ${index + 1}`,
      comp.age.name,
      comp.type.name,
    ]),
    financialFamilyEntries: familyInfo.familyReport['7'].family
      .map((entry) => [
        entry.parent.name,
        entry.age, entry.entryType.name,
        Number(entry.monthlyIncome).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
      ]),
    financialOtherEntries: familyInfo.familyReport['7'].contributor
      .map((entry) => [
        entry.contributor.name,
        entry.contributionType.name,
        Number(entry.monthlyContribution).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
      ]),
    isDivorcedParents: familyInfo.familyReport['8'].divorced === 'No' ? 'NO' : 'SI',
    isMultiPlaceLiving: familyInfo.familyReport['8'].placeOfLiving === 'No' ? 'NO' : 'SI',
    housingOneCosts: [
      'Costos mensuales de vivienda del hogar 1',
      !familyInfo.familyReport['8'].houseOwners ? 'NO' : 'SI',
      familyInfo.familyReport['8'].typeOfHousing.name,
      Number(familyInfo.familyReport['8'].costOfHousing).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
    ],
    housingTwoCosts: [
      'Costos mensuales de vivienda del hogar 2',
      !familyInfo.familyReport['8'].houseOwnersTwo ? 'NO' : 'SI',
      familyInfo.familyReport['8'].typeOfHousingTwo.name,
      Number(familyInfo.familyReport['8'].costOfHousingTwo).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
    ],
    isDeceased: !familyInfo.familyReport['9'].deceasedParent ? 'NO' : 'SI',
    deceasedDetails: familyInfo.familyReport['9'].deceasedParentDescription,
    costDetails: familyInfo.familyReport['9'].details
      .map((cost) => [
        cost.details,
        Number(cost.monthlyCost).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
      ]),
    costLimit: Number(familyInfo.familyReport['10'].costLimit).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
    extraEarnings: Number(familyInfo.familyReport['10'].extraEarnings).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
    familyIncome: Number(familyInfo.familyReport['10'].familyIncome).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
    maximunCapacity: Number(familyInfo.familyReport['10'].maximunCapacity).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
    percentualIncome: Number(familyInfo.familyReport['10'].percentualIncome),
    specialNeeds: !familyInfo.familyReport['11'].specialNeeds ? 'NO' : 'SI',
    specialNeedsDescription: familyInfo.familyReport['11'].specialNeedsDescription,
    parentsEducation: familyInfo.familyReport['11'].parentsEducation,
  };

  const mainContent = [
    {
      text: [
        {
          text: 'VACANTED™', fontSize: 35, style: 'header',
        },
      ],
      style: 'header',
      bold: true,
      color: '#36a7eb',
      alignment: 'center',
      margin: [0, 0, 0, 20],
    },

    // title and subtitles with informations
    {
      text: 'INFORME DE FAMILIA',
      style: 'header',
      fontSize: 25,
      bold: true,
      alignment: 'center',
      margin: [0, 30, 0, 5],
    },
    {
      text: [
        { text: 'Identificación de familia: ', bold: true },
        { text: `${report.email}`, bold: false },
      ],
      style: 'subheader',
      fontSize: 12,
      alignment: 'center',
    },
    {
      text: [
        { text: 'Fecha y hora de última actualización: ', bold: true },
        { text: `${report.lastUpdate}`, bold: false },
      ],
      style: 'subheader',
      fontSize: 12,
      alignment: 'center',
    },

    // 1- Presentación de quien completa el informe
    {
      text: '1- Presentación de quien completa el informe',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 40, 0, 10],
    },
    {
      text: 'Por favor, selecciona quién es el integrante familiar que completará este informe. Puedes realizar una breve descripción si lo deseas.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: [120, '*'],
        heights: ['*', 30],
        body: [
          [
            { text: 'Integrante que completa el informe ', bold: true },
            { text: 'Descripción', bold: true },
          ],
          [`${report.parent}`, `${report.parentDescription}`],
        ],
      },
    },

    // 2- Presentación de la Familia
    {
      text: '2- Presentación de la Familia',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      text: 'Por favor, realiza una presentación detallada de tu familia.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*'],
        heights: [50],
        body: [
          [`${report.familyPresentation}`],
        ],
      },
    },

    // 3- Objetivos buscados por la Familia
    {
      text: '3- Objetivos buscados por la Familia',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      text: 'Por favor, detalla los objetivos educacionales que se persiguen.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*'],
        heights: [50],
        body: [
          [`${report.familyObjective}`],
        ],
      },
    },

    // 4- Requerimientos de la Familia
    {
      text: '4- Requerimientos de la Familia',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      text: 'Por favor, describe los requerimientos más importantes que debería cumplir la Institución Educativa a la que concurran tus hijos.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*'],
        heights: [50],
        body: [
          [`${report.familyRequirements}`],
        ],
      },
    },

    // 5- Localización geográfica aproximada y distancia
    {
      text: '5- Localización geográfica aproximada y distancia',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: [100, '*'],
        body: [
          [{ text: 'Ciudad', bold: true }, `${report.city}`],
          [{ text: 'Dirección', bold: true }, `${report.address}`],
        ],
      },
    },

    // 6- Composición familiar en etapa estudiantil
    {
      text: '6- Composición familiar en etapa estudiantil',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      text: 'A continuación, agrega los integrantes de la familia en etapa estudiantil para los niveles de inicial, primaria, secundaria o bachillerato. Además, selecciona la edad y el tipo de institución a la que hoy concurren. En caso de que no estén escolarizados aún, seleccionar edad y la opción “No escolarizado aún”.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', '*', '*'],
        body: [
          [
            '',
            { text: 'Edad (Años)', bold: true },
            { text: 'Tipo de institución actual', bold: true },
          ],
          ...report.familyComposition,
        ],
      },
    },

    // 7- Composición familiar restante e ingresos económicos
    {
      text: '7- Composición familiar restante e ingresos económicos',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      text: 'A continuación, agrega los restantes integrantes del núcleo familiar. Indica sus edades y sus contribuciones económicas al presupuesto familiar.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', '*', '*', '*'],
        body: [
          [
            { text: 'Integrante', bold: true },
            { text: 'Edad (Años)', bold: true },
            { text: 'Tipo de ingreso', bold: true },
            { text: 'Ingreso líquido promedio mensual', bold: true },
          ],
          ...report.financialFamilyEntries,
        ],
      },
    },

    // 8- Otros aportantes al presupuesto educativo de la familia
    {
      text: '8- Otros aportantes al presupuesto educativo de la familia',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      text: 'A continuación, agrega las personas por fuera del núcleo familiar o las instituciones que también contribuyen al presupuesto educativo familiar.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 0, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', '*', '*'],
        body: [
          [
            { text: 'Aportante', bold: true },
            { text: 'Tipo de aporte', bold: true },
            { text: 'Aporte promedio mensual', bold: true },
          ],
          ...report.financialOtherEntries,
        ],
      },
    },

    // 9- Capacidad de pago - Vivienda
    {
      text: '9- Capacidad de pago - Vivienda',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', 80],
        body: [
          [
            {
              border: [false, false, false, false],
              text: '',
            },
            { text: 'SI / NO', bold: true },
          ],
          [{ text: 'Los padres del núcleo familiar están separados o divorciados?', bold: true }, report.isDivorcedParents],
          [{ text: 'Los integrantes de la familia en edad estudiantil viven en más de un hogar?', bold: true }, report.isMultiPlaceLiving],
        ],
      },
      margin: [0, 0, 0, 20],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', '*', '*', '*'],
        body: [
          [
            { text: 'Hogares', bold: 'true' },
            { text: 'Propietario', bold: 'true' },
            { text: 'Concepto de gasto', bold: 'true' },
            { text: 'Costo promedio mensual', bold: 'true' },
          ],
          report.housingOneCosts,
          report.housingTwoCosts,
        ],
      },
    },

    // 10- Capacidad de pago - Varios
    {
      text: '10- Capacidad de pago - Varios',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', 80],
        body: [
          [
            {
              border: [false, false, false, false],
              text: '',
            },
            { text: 'SI / NO', bold: true },
          ],
          [{ text: '¿Algún integrante del núcleo familiar es fallecido?', bold: true }, report.isDeceased],
          [
            {
              colSpan: 2,
              text: report.deceasedDetails,
            },
          ],
        ],
      },
    },
    {
      text: 'Detalle hasta 3 situaciones especiales que impliquen un costo mensual adicional permanente y su impacto en el presupuesto familiar.',
      style: 'subheader',
      fontSize: 12,
      margin: [0, 15, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', '*'],
        body: [
          [
            { text: 'Detalle de situación', bold: 'true' },
            { text: 'Costo promedio mensual', bold: 'true' },
          ],
          ...report.costDetails,
        ],
      },
    },

    // 11- Capacidad de pago - Resumen
    {
      text: '11- Capacidad de pago - Resumen',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: [400, '*'],
        body: [
          ['Ingresos líquidos del núcleo familiar', report.familyIncome],
          ['Porcentaje sobre los ingresos que a su entender puede destinar como máximo a la educación de todos los integrantes de su familia.', `${report.percentualIncome}%`],
          ['Capacidad de pago mensual del núcleo familiar asignada al costo educativo', report.costLimit],
          ['Ingresos líquidos por fuera del núcleo familiar', report.extraEarnings],
          [
            {
              fontSize: 14,
              bold: true,
              text: 'Capacidad de pago asignada al costo educativo total familiar',
            },
            {
              fontSize: 14,
              bold: true,
              text: report.maximunCapacity,
            },
          ],
        ],
      },
    },

    // 12- Otras informaciones
    {
      text: '12- Otras informaciones',
      style: 'header',
      fontSize: 18,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*', 80],
        body: [
          [
            {
              border: [false, false, false, false],
              text: '',
            },
            { text: 'SI / NO', bold: true },
          ],
          [{ text: '¿Algún integrante de la familia tiene capacidades diferentes?', bold: true }, report.specialNeeds],
          [
            {
              colSpan: 2,
              text: report.specialNeedsDescription,
            },
          ],
        ],
      },
    },
    {
      text: 'Educación de los padres o personas a cargo',
      style: 'subheader',
      fontSize: 12,
      bold: true,
      margin: [0, 25, 0, 10],
    },
    {
      style: 'tableExample',
      table: {
        widths: ['*'],
        body: [
          [report.parentsEducation],
        ],
      },
    },
  ];

  function footer(currentPage, pageCount) {
    return [
      {
        text: `${currentPage} de ${pageCount}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 40, 0],
      },
    ];
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 50, 40, 45],

    content: [mainContent],
    footer,
  };

  pdfMake.createPdf(docDefinition).download(`${report.email}_${report.lastUpdate}.pdf`);
};

export default pdfGenerator;
