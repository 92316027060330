import React, { useState } from 'react';

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import useFilteredFavorites from 'hooks/useFilteredFavorites';
// core components
import ReactTable from 'components/ReactTable/ReactTable';
import CardWithOutTable from 'components/CardWithOutTable';
import SortBy from 'components/SortBy';
import LoadingContent from 'components/LoadingContent';
import '../../assets/css/searches.css';

function ReactTables() {
  const [pageSelect, setPageSelect] = useState({
    value: 'Seleccionar una búsqueda guardada',
    label: 'Seleccione',
  });

  const [filters, schools, setSchools] = useFilteredFavorites(pageSelect, setPageSelect);
  const title = 'Mis Instituciones Favoritas';
  const subtitle = 'En esta sección, encontrarás las Instituciones Educativas que seleccionaste como favoritas.';

  if (!filters) {
    return (
      <LoadingContent title={title} subtitle={subtitle} />
    );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 className="title">{title}</h3>
            <p className="category">
              {subtitle}
            </p>
            <Card className="card-table">
              <Card.Body>
                <ReactTable
                  data={schools}
                  moreFeatures={
                    [
                      <SortBy getComponent={schools} setComponent={setSchools} />,
                    ]
                  }
                  fills={filters}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
            <div className="mobileVersion">
              <SortBy getComponent={schools} setComponent={setSchools} />
              <CardWithOutTable
                data={schools}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReactTables;
