import moment from 'moment';

export default function formatDates(date, language) {
  if (!date) return null;

  moment.locale(language);
  moment.updateLocale('es', {
    months: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
    ],
  });

  const dateFormat = moment(date).format('DD [de] MMMM[,] YYYY');
  const hourFormat = moment(date).format('hh:mm:ss');

  return { dateFormat, hourFormat };
}
