import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getUrl } from 'Router/urlConfig';

import CannotPostulateModal from 'components/Modals/cannotPostulateModal';
import { savePostulationAlert } from 'Services/api';

import styles from './styles.module.css';
import '../../assets/css/cardWithOutTable.css';

function CardWithOutTable({ data }) {
  const appURL = getUrl('app');
  const [
    isCannotPostulateModalOpen, setIsCannotPostulateModalOpen,
  ] = useState({ status: false, school: {} });

  /* const handleModal = (school) => {
    if (!school.postulate) {
      setIsCannotPostulateModalOpen({ status: !isCannotPostulateModalOpen.status, school });
    } else {
      window.open(`${appURL}/postulate/${school.id}`, '_self');
    }
  }; */

  const handleModal = (school) => {
    window.open(`${appURL}/school/${school.id}`, '_blank');
  };

  const handlePostulation = async (check, school) => {
    if (school) {
      const { id, postulate } = school;
      if (!postulate) {
        const alert = check;
        const postulationAlert = {
          school_id: id,
          postulated: false,
          alert,
        };

        await savePostulationAlert(postulationAlert);
      }
    }

    setIsCannotPostulateModalOpen((current) => ({ ...current, status: false }));
  };

  return (
    <div className={`cardShowTable ${styles.cardMobile}`}>
      {data.map((school) => (
        <Card
          key={school.id}
          className={`mb-4 ${styles.card}`}
        >
          <Card.Body>
            <Card.Title onClick={() => window.location.replace(`${appURL}/school/${school.id}`)}>{school.name}</Card.Title>
            <Card.Text>
              {school.public}
            </Card.Text>
            <Card.Text className="mb-2 text-muted">
              {`Distancia: ${school.distance} Km`}
            </Card.Text>
            <Card.Text className="mb-2 text-muted">
              {`Afinidad ${school.schoolAffinity ? school.schoolAffinity.totalAffinity : '-'}%`}
            </Card.Text>
            <Button
              className={`${styles.cardButton}`}
              onClick={() => handleModal(school)}
            >
              VER INFO O CONTACTAR

            </Button>
          </Card.Body>
        </Card>
      ))}
      <CannotPostulateModal
        isOpen={isCannotPostulateModalOpen.status}
        school={isCannotPostulateModalOpen.school}
        onRequestClose={(check, school) => handlePostulation(check, school)}
      />
    </div>
  );
}

export default CardWithOutTable;

CardWithOutTable.propTypes = {
  data: PropTypes.arrayOf([PropTypes.any]),
}.isRequired;
