import React from 'react';
import { Card, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import style from './styles.module.css';

const FamilyInitialContainer = ({ children, path }) => (
  <Col>
    <Card style={{ height: 100 }} className={`justify-content-center align-items-center ${style.card}`}>
      <Nav.Link
        to={path}
        as={Link}
        className={`${style.link}`}
      >
        {children}
      </Nav.Link>
    </Card>
  </Col>
);

FamilyInitialContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  path: PropTypes.string.isRequired,
};

export default FamilyInitialContainer;
