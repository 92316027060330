import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useUserData } from 'Contexts/UserContext';
import checkLoginStatus from 'dist/Utils/checkLoginStatus';
import Sidebar from 'components/Sidebar/Sidebar';
// import AdminNavbar from 'components/Navbars/AdminNavbar';

import sidebarBackgroundImage from 'assets/img/Area_privada_familia_back_izq.jpg';

// dinamically create dashboard routes
import generateRandomString from 'dist/Utils/stringManipulation';
import FamilyDashBoard from 'views/DashBoards/FamilyDashBoard';
// import Searches from 'views/Searches';
import FamilyInfo from 'views/FamilyInfo';
import AdmissionsSent from 'views/ AdmissionsSent';
import DoneVacantests from 'views/Vacantests/DoneVacantests';
import Favorites from 'views/Favorites';
import Chats from 'views/Chats';
import { getUrl } from 'Router/urlConfig';

function Family() {
  const { userData, setUserData } = useUserData();
  const loggedUser = userData.loginStatus;
  const vacantestURL = getUrl('vacantest');

  useEffect(() => {
    checkLoginStatus(setUserData);
  }, []);

  function getViewComponent(routeProp, prop) {
    const componentHash = {
      FamilyDashBoard: () => <FamilyDashBoard {...routeProp} />,
      // Searches: () => <Searches {...routeProp} />,
      Vacantest: () => <DoneVacantests {...routeProp} />,
      Favorites: () => <Favorites {...routeProp} />,
      FamilyInfo: () => <FamilyInfo {...routeProp} />,
      AdmissionsSent: () => <AdmissionsSent {...routeProp} />,
      FamilyChats: () => <Chats userRole="family" {...routeProp} />,
    };

    return componentHash[prop.component]();
  }

  const getPrivateRoutes = (routeList) => routeList.map((prop, key) => {
    if (prop.collapse) {
      return getPrivateRoutes(prop.views);
    }
    if (prop.layout.match(/family/)) {
      return (
        <Route
          path={prop.layout + prop.path}
          key={generateRandomString(key)}
          render={(routeProp) => getViewComponent(routeProp, prop)}
        />
      );
    }
    return null;
  });

  return loggedUser ? (
    <>
      <div className="wrapper">
        <Sidebar
          routes={userData.data.routes}
          image={sidebarBackgroundImage}
          background=""
        />
        <div className="main-panel">
          {/* <AdminNavbar /> */}
          <div className="content">
            <Switch>
              {getPrivateRoutes(userData.data.routes)}
              <Route
                path="/family/new_test"
                component={() => {
                  window.location.replace(vacantestURL);
                  return null;
                }}
              />
            </Switch>
          </div>
          <button
            type="button"
            className="close-layer"
            onClick={() => document.documentElement.classList.toggle('nav-open')}
          >
            {' '}
          </button>
        </div>
      </div>
    </>
  ) : <p>Loading...</p>;
}

export default Family;
