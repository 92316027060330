import { useState, useEffect } from 'react';

import getFavorites from 'dist/Collections/collectionFavorites';

export default function useFilteredSchools() {
  const [filters, setFilters] = useState();
  const [schools, setSchools] = useState([]);

  function setDropDown(initialFilter) {
    if (!initialFilter) return null;

    setSchools(...[initialFilter]);

    return null;
  }

  useEffect(() => {
    async function getFilters() {
      const filter = await getFavorites();
      if (!filter) return null;

      const initialFilter = filter.data.favoriteSchools;

      setDropDown(initialFilter);

      return setFilters(filter.data.length > 0 ? [...filter.data.favoriteSchools] : []);
    }

    getFilters();
  }, []);

  return [filters, schools, setSchools];
}
