import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// react-bootstrap components
import {
  Collapse,
  Nav,
} from 'react-bootstrap';
import { getCollapseStates, getCollapseInitialState } from 'dist/routes';
import generateRandomString from 'dist/Utils/stringManipulation';
import logout from 'dist/Utils/logout';

import { getUrl } from 'Router/urlConfig';

import logo from 'assets/img/Vacanted_LOGO_header.png';
import './sidebar.css';
// import avatar from 'assets/img/default-avatar.png';
import { useUserData } from 'Contexts/UserContext';

function Sidebar({ routes, image, background }) {
  // to check for active links and opened collapses
  const location = useLocation();
  // this is for the user collapse
  const [userCollapseState, setUserCollapseState] = React.useState(false);
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  React.useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);

  const { userData } = useUserData();
  const { data: familyData } = userData;
  const activeRoute = (routeName) => (location.pathname === routeName ? 'active' : '');

  const createLinks = (routeList) => routeList.map((prop, key) => {
    if (prop.collapse) {
      const st = {};
      st[prop.state] = !state[prop.state];
      return (
        <Nav.Item
          className={getCollapseInitialState(prop.views, location) ? 'active' : ''}
          as="li"
          key={generateRandomString(key)}
        >
          <Nav.Link
            className={state[prop.state] ? 'collapsed' : ''}
            data-toggle="collapse"
            onClick={(e) => {
              e.preventDefault();
              setState({ ...state, ...st });
            }}
            aria-expanded={state[prop.state]}
          >
            <p>
              {prop.name}
            </p>
          </Nav.Link>
          <Collapse in={state[prop.state]}>
            <div>
              <Nav as="ul">{createLinks(prop.views)}</Nav>
            </div>
          </Collapse>
        </Nav.Item>
      );
    }
    return (
      <Nav.Item
        className={activeRoute(prop.layout + prop.path)}
        key={generateRandomString(key)}
        as="li"
        onClick={() => document.documentElement.classList.toggle('nav-open')}
      >
        <Nav.Link to={prop.layout + prop.path} as={Link}>
          {prop.icon ? (
            <>
              <p>{prop.name}</p>
            </>
          ) : (
            <>
              <span className="sidebar-normal">{prop.name}</span>
            </>
          )}
        </Nav.Link>
      </Nav.Item>
    );
  });
  // verifies if routeName is the one active (in browser input)

  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            <a href={`${getUrl('app')}/`}>
              <img
                src={logo}
                alt="logo-vacanted"
              />
            </a>
          </div>
          <div className="user">
            <div className="photo">
              <img
                alt="profile"
                src={familyData.user.avatar}
              />
            </div>
            <div className="info">
              <a
                className={userCollapseState ? 'collapsed' : ''}
                data-toggle="collapse"
                href="#user"
                onClick={(e) => {
                  e.preventDefault();
                  setUserCollapseState(!userCollapseState);
                }}
                aria-expanded={userCollapseState}
              >
                <span>
                  {familyData.user.name}
                </span>
              </a>

            </div>
          </div>
          {false && <Nav as="ul">{createLinks(routes)}</Nav>}
          <Nav
            /* className="footerNav" */
            as="ul"
          >
            {/* <hr /> */}
            <Nav.Item
              as="li"
              className="exit"
              key="exit"
              onClick={() => window.location.replace(`${getUrl('app')}/experience`)}
            >
              <Nav.Link>
                Volver a la página principal
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              as="li"
              className="exit"
              key="exit"
              onClick={() => logout()}
            >
              <Nav.Link>
                Cerrar Sesión
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        />
      </div>
    </>
  );
}

const linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.string]),
};

Sidebar.defaultProps = {
  image: '',
  // background: '',
  // routes: [],
};

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf([
    'black',
    'azure',
    'green',
    'orange',
    'purple',
    '',
  ]).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropTypes,
    }),
    // PropTypes.oneOfType([
    //   PropTypes.shape({
    //     collapse: true,
    //     path: PropTypes.string,
    //     name: PropTypes.string,
    //     state: PropTypes.string,
    //     views: PropTypes.shape({
    //       ...linkPropTypes,
    //       mini: PropTypes.string,
    //     }),
    //   }),
    // ]),
  ).isRequired,
};

export default Sidebar;
