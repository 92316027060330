import React, { useState } from 'react';

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import { getUrl } from 'Router/urlConfig';
import useFilterVacantest from 'hooks/useFilterVacantest';
// core components
import ReactTable from 'components/ReactTable/ReactTable';
import CardWithOutTable from 'components/CardWithOutTable';
import DropDownSelect from 'components/DropDownSelect';
import LoadingContent from 'components/LoadingContent';
import '../../assets/css/searches.css';

function ReactTables() {
  const Vacantest = getUrl('vacantest');
  const [pageSelect, setPageSelect] = useState({
    value: 'Seleccionar una búsqueda guardada',
    label: 'Seleccione',
  });

  const [
    filters,
    schools,
  ] = useFilterVacantest(pageSelect, setPageSelect);

  const title = 'Mis Tests de Compatibilidad';
  const subtitle = 'En esta sección, encontrarás los Centros Educativos que fueron recomendados en tus Tests de Compatibilidad.';

  if (!filters) {
    return (
      <LoadingContent title={title} subtitle={subtitle} />
    );
  }
  if (filters.length <= 0) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <h3 className="title">{title}</h3>
              <p className="category mb-5">
                {subtitle}
              </p>
              <Card className="card-table">
                <Card.Body>
                  <Col className="cardHeader">
                    <DropDownSelect
                      filters={filters}
                      pageSelect={pageSelect}
                      setPageSelect={setPageSelect}
                    />
                  </Col>
                  <div className="areaWithoutSchools">
                    <p>NO HAY TESTS.</p>
                    <Button
                      onClick={
                        () => window.location.replace(Vacantest)
                      }
                    >
                      REALIZAR TEST DE COMPATIBILIDAD
                    </Button>
                  </div>
                </Card.Body>
              </Card>
              <div className="mobileVersion">
                <DropDownSelect
                  filters={filters}
                  pageSelect={pageSelect}
                  setPageSelect={setPageSelect}
                />
                <div className="areaWithoutSchools">
                  <p>
                    NO HAY TESTS.
                    <Button
                      onClick={
                        () => window.location.replace(Vacantest)
                      }
                    >
                      REALIZAR TEST DE COMPATIBILIDAD
                    </Button>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 className="title">{title}</h3>
            <p className="category mb-5">
              {subtitle}
            </p>
            <Card className="card-table">
              <Card.Body>
                <Col className="cardHeader mb-5">
                  <DropDownSelect
                    filters={filters}
                    pageSelect={pageSelect}
                    setPageSelect={setPageSelect}
                  />
                </Col>
                <ReactTable
                  data={schools}
                  fills={filters}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
            <div className="mobileVersion">
              <DropDownSelect
                filters={filters}
                pageSelect={pageSelect}
                setPageSelect={setPageSelect}
              />
              <CardWithOutTable
                data={schools}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReactTables;
