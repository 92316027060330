import React from 'react';
import {
  Card,
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import SelfSpinner from 'components/SelfSpinner';
import PropTypes from 'prop-types';

export default function LoadingContent({ title, subtitle }) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 className="title">{title}</h3>
            <p className="category">
              {subtitle}
            </p>
            <Card>
              <Card.Body>
                <SelfSpinner />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

LoadingContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}.isRequired;
