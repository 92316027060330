import axios from 'axios';
import { getUrl } from 'Router/urlConfig';

const apiURL = getUrl('api');
const api = axios.create({ baseURL: apiURL });

export async function savePostulationAlert(payload) {
  const user = JSON.parse(localStorage.getItem('vacanted'));

  if (!user || !user.token) return false;

  const data = payload;
  const config = {
    headers: {
      authorization: user.token,
    },
  };

  return api.post(`${apiURL}/postulate/create`, data, config)
    .then((resp) => resp)
    .catch((error) => error);
}

export default api;
