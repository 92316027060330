import { useState, useEffect } from 'react';

import getVacantest from 'dist/Collections/collectionVacantests';
import formatDates from 'dist/FormatDates/formatDates';

export default function useFilteredSchools(pageSelect, setPageSelect) {
  const [filters, setFilters] = useState();
  const [schools, setSchools] = useState([]);
  const [dateSaveSearch, setDateSaveSearch] = useState();

  function searchInArray() {
    if (!filters) return null;
    const objectOfSearch = filters.find((item) => (item.name === pageSelect.value));

    return objectOfSearch;
  }

  async function setDropDown(initialFilter) {
    if (!initialFilter) return null;

    function compare(a, b) {
      if (a.schoolAffinity.totalAffinity > b.schoolAffinity.totalAffinity) {
        return -1;
      }
      if (a.schoolAffinity.totalAffinity < b.schoolAffinity.totalAffinity) {
        return 1;
      }
      return 0;
    }

    const organizedValuesOfTheOldest = Object.values(initialFilter.schools).sort(compare);

    organizedValuesOfTheOldest.map((position) => position);
    setSchools(...[organizedValuesOfTheOldest]);

    // setSchools(...[initialFilter.schools]);

    setDateSaveSearch(`${formatDates(initialFilter.savedAt, 'es').dateFormat} -
    ${formatDates(initialFilter.savedAt, 'es').hourFormat}`);

    setPageSelect({
      value: initialFilter.name,
      label: `Test realizado
      ${formatDates(initialFilter.savedAt, 'es').dateFormat}
      ${formatDates(initialFilter.savedAt, 'es').hourFormat}`,
    });

    return null;
  }

  useEffect(() => {
    const filterData = searchInArray();
    if (!filterData) return null;

    function compare(a, b) {
      if (a.schoolAffinity.totalAffinity > b.schoolAffinity.totalAffinity) {
        return -1;
      }
      if (a.schoolAffinity.totalAffinity < b.schoolAffinity.totalAffinity) {
        return 1;
      }
      return 0;
    }

    const organizedValuesOfTheOldest = Object.values(filterData.schools).sort(compare);

    organizedValuesOfTheOldest.map((position) => position);
    setSchools(...[organizedValuesOfTheOldest]);

    return setDateSaveSearch(`${formatDates(filterData.savedAt, 'es').dateFormat} - ${formatDates(filterData.savedAt, 'es').hourFormat}`);
  }, [pageSelect]);

  useEffect(() => {
    async function getFilters() {
      const filter = await getVacantest();
      if (!filter) return null;

      const lengthFilter = filter.data?.length;
      const initialFilter = filter.data[lengthFilter - 1];

      const data = [...filter.data];
      setFilters(data);

      return (setDropDown(initialFilter));
    }

    getFilters();
  }, []);

  return [filters, schools, dateSaveSearch];
}
