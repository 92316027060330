import React, { useEffect, useState, useRef } from 'react';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane, faFileUpload, faArrowLeft, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {
  MessageList,
} from 'react-chat-elements';
import {
  getFirestore, doc, onSnapshot, updateDoc, arrayUnion, increment,
  // connectFirestoreEmulator,
} from 'firebase/firestore';
import {
  getStorage, ref, getBlob,
  // connectStorageEmulator,
} from 'firebase/storage';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import api from 'Services/api';
import chatUtils from './utils';
import groupBy from '../../dist/Utils/groupBy';
import dateToDay from '../../dist/Utils/dateToDay';
import dateToTime from '../../dist/Utils/dateToTime';
// import FileLoader from './FileLoader';
import SimpleFileLoader from './SimpleFileLoader';
import useFirebase from './useFirebase';

import './Conversation.scss';

const db = getFirestore();
// connectFirestoreEmulator(db, 'localhost', 5003);
const storage = getStorage();
// connectStorageEmulator(storage, 'localhost', 5004);

const ConversationV2 = ({
  chatId, handleBack, userRole, fitParentHeight, refresh,
}) => {
  const { token } = getDataFromLocalStorage('vacanted');
  const [selectedConversation, setSelectedConversation] = useState();
  const [snapshot, setSnapshot] = useState();
  // const [unsubscribeFromLastChat, setUnsubscribeFromLastChat] = useState();
  const [chatName, setChatName] = useState();
  const [newMessage, setNewMessage] = useState('');
  const [showPreviousFilesDialog, setShowPreviousFilesDialog] = useState(false);
  const selectedConversationRef = useRef();
  const unsubscribeRef = useRef();

  const { firebaseInfoRef, firebaseInfo } = useFirebase();

  const updateOpenChat = (openChatId) => {
    if (chatUtils.isAdmin(userRole)) return;
    const openChat = openChatId ? { id: openChatId, timestamp: Date.now() } : null;
    const memberRef = doc(db, 'members', `${firebaseInfoRef.current.id}`);
    updateDoc(memberRef, { openChat })
      .catch((err) => chatUtils.saveErrorInFirebase(db, firebaseInfoRef.current.id, 'Failed to update open chat', err));
  };

  const unsubscribeIfNeeded = () => {
    if (unsubscribeRef.current) {
      const fun = unsubscribeRef.current;
      fun();
    }
  };

  const onClose = () => {
    if (selectedConversationRef.current) updateOpenChat(null);
    unsubscribeIfNeeded();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onClose);

    return () => {
      window.removeEventListener('beforeunload', onClose);
      onClose();
    };
  }, []);

  const subscribeToChat = (chatIdToSubscribe) => {
    const chatRef = doc(db, 'chats', chatIdToSubscribe);
    const _u = onSnapshot(chatRef, (snap) => {
      setSnapshot(snap);
    });
    unsubscribeRef.current = _u;
  };

  useEffect(() => {
    if (chatId) {
      unsubscribeIfNeeded();
      subscribeToChat(chatId);
    }
  }, [chatId]);

  const refreshSchoolReferral = (reason) => api.post('/school/referrals/refresh',
    { chat_id: chatId, reason },
    { headers: { authorization: token } })
    .catch((err) => {
      chatUtils.saveErrorInFirebase(db, firebaseInfo?.id, `Failed to refresh referral ${chatId}`, err);
    });

  const markChatAsRead = () => {
    if (chatUtils.isAdmin(userRole)) return;
    const chatRef = doc(db, 'chats', chatId);
    const unreadField = `participants.${firebaseInfo.id}.unread`;
    const notifiedField = `participants.${firebaseInfo.id}.notified`;
    const update = {
      [unreadField]: 0,
      [notifiedField]: false,
    };
    updateDoc(chatRef, update).then(() => refreshSchoolReferral('READ')).catch((err) => chatUtils.saveErrorInFirebase(`Failed to mark as read ${chatId}`, err));
  };

  useEffect(() => {
    if (snapshot) {
      const selectedChatData = { ...snapshot.data(), id: snapshot.id };
      if (!chatUtils.isAdmin(userRole)
        && selectedChatData.participants[firebaseInfo.id].unread) {
        markChatAsRead();
      }
      setSelectedConversation(selectedChatData);
    }
  }, [snapshot]);

  const scrollChatToBottom = () => {
    setTimeout(() => {
      const elem = document.getElementsByClassName('rce-mlist')[0];
      elem.scroll({ top: elem.scrollHeight, behavior: 'smooth' });
    }, 100);
  };

  useEffect(() => {
    if (selectedConversation) {
      selectedConversationRef.current = selectedConversation;
      updateOpenChat(selectedConversation.id);
      const otherParticipantNames = chatUtils.getOtherParticipantNamesConcat(
        selectedConversation.participants,
        firebaseInfo.id,
      );
      setChatName(otherParticipantNames);
      if (selectedConversation.messages?.length) {
        scrollChatToBottom();
      }
    }
  }, [selectedConversation]);

  const handleNewMessageChange = (e) => {
    const {
      target: { value },
    } = e;
    setNewMessage(value);
  };

  const sendChatMessage = (msg) => {
    if (!msg?.text || !selectedConversation) return;

    const newMessageObj = {
      sender: firebaseInfo.id,
      ...msg,
      timestamp: Date.now(),
    };
    const chatRef = doc(db, 'chats', selectedConversation.id);

    const memberUpdate = {};
    const chatUpdate = {
      messages: arrayUnion(newMessageObj),
      updatedAt: Date.now(),
    };
    if (!selectedConversation.chatMode) {
      chatUpdate.chatMode = true;
    }
    if (!selectedConversation.pendingProcess) {
      chatUpdate.pendingProcess = true;
    }

    const otherParticipantIds = chatUtils.getOtherParticipantIds(selectedConversation.participants);
    otherParticipantIds.forEach((otherId) => {
      if (`${msg.type}`.includes('file')) {
        const fileSharedField = `filesSharedWith.${otherId}`;
        memberUpdate[fileSharedField] = arrayUnion(`${msg.path}`);
      }

      const unreadField = `participants.${otherId}.unread`;
      chatUpdate[unreadField] = increment(1);
    });

    if (Object.keys(memberUpdate).length) {
      const memberRef = doc(db, 'members', firebaseInfo.id);
      updateDoc(memberRef, memberUpdate).catch((err) => chatUtils.saveErrorInFirebase(db, firebaseInfo.id, `Failed to update member ${firebaseInfo.id}`, err));
    }

    updateDoc(chatRef, chatUpdate).then(() => {
      if (chatUpdate.chatMode) {
        refreshSchoolReferral('CHAT_MODE').then(() => {
          if (refresh) {
            refresh();
          }
        });
      }
    }).catch((err) => chatUtils.saveErrorInFirebase(db, firebaseInfo.id, `Failed to update chat ${selectedConversation.id}`, err));
  };

  const handleSendChatMessage = (e) => {
    e.preventDefault();
    const trimmedMessage = newMessage.trim();
    if (trimmedMessage) {
      const message = { type: 'text', text: trimmedMessage };
      sendChatMessage(message);
      setNewMessage('');
    }
  };

  const handleDownload = (item, _index, _event) => {
    if (chatUtils.isAdmin(userRole)) return;
    const { data } = item;
    getBlob(ref(storage, data.status.path))
      .then((blob) => {
        FileSaver(blob, item.text);
      })
      .catch((error) => chatUtils.saveErrorInFirebase(db, firebaseInfo.id, `Failed to download file from chat ${data}`, error));
  };

  const deleteMessageByTimestamp = (timestamp) => {
    const chatRef = doc(db, 'chats', chatId);

    const deletedField = `deleted.${timestamp}`;
    const update = {};
    update[deletedField] = true;
    updateDoc(chatRef, update).catch((err) => chatUtils.saveErrorInFirebase(db, firebaseInfo.id, `Failed to delete message ${selectedConversation.id}`, err));
  };

  const handleDeleteMessage = (item, _index, _event) => {
    const { timestamp } = item;
    deleteMessageByTimestamp(timestamp);
  };

  const renderChatMessages = () => {
    const testMsgList = [];
    if (selectedConversation?.messages) {
      const messagesByDay = groupBy(selectedConversation.messages, (m) => dateToDay(m.timestamp));
      Object.keys(messagesByDay).forEach((day) => {
        testMsgList.push({
          type: 'system',
          text: day,
        });
        let lastSender = null;
        messagesByDay[day].forEach(({
          text, sender, timestamp, type: messageType, path,
        }) => {
          if (!sender) {
            testMsgList.push({
              type: 'system',
              text,
            });
          } else {
            const senderParticipant = selectedConversation.participants[sender];
            const msgSenderName = chatUtils.isSchool(sender)
              ? senderParticipant.name
              : senderParticipant.email;
            const deletedMsg = selectedConversation.deleted && selectedConversation.deleted[`${timestamp}`];
            testMsgList.push({
              type: deletedMsg || !messageType ? 'text' : messageType,
              title: sender !== firebaseInfo.id && (!lastSender || lastSender !== sender)
                ? msgSenderName
                : null,
              text: deletedMsg ? 'Este mensaje fue eliminado' : text,
              dateString: dateToTime(timestamp),
              date: timestamp,
              position: sender === firebaseInfo.id ? 'right' : 'left',
              removeButton: sender === firebaseInfo.id && !deletedMsg,
              className: deletedMsg ? 'deleted' : '',
              timestamp,
              data: {
                status: {
                  path,
                },
              },
            });
          }
          lastSender = sender;
        });
      });
    }
    return (
      <MessageList
        className="conversation-chat"
        lockable
        toBottomHeight="100%"
        dataSource={testMsgList}
        onDownload={handleDownload}
        onRemoveMessageClick={handleDeleteMessage}
      />
    );
  };

  const handleSelectedFile = (selectedFile) => {
    if (selectedFile) {
      const message = { type: 'file', text: selectedFile.name, path: selectedFile.fullPath };
      sendChatMessage(message);
    }
    setShowPreviousFilesDialog(false);
  };

  if (!selectedConversation) {
    return <FontAwesomeIcon className="fa-spin centered-icon darkest" icon={faSpinner} size="4x" />;
  }

  return (
    <div className={`conversation-container${fitParentHeight ? ' fit-height' : ''}`} id="conversation-container">
      <div className="conversation-header">
        {handleBack && (
        <div className="conversation-back-button-container">
          <FontAwesomeIcon className="conversation-back-button" icon={faArrowLeft} size="sm" onClick={handleBack} />
        </div>
        )}
        <div className="contact-name-container">
          <p>{chatName}</p>
        </div>
      </div>
      {renderChatMessages()}
      <div className="message-sender">
        {(!chatUtils.isAdmin(userRole) && (selectedConversation.chatMode
          || selectedConversation.createdBy !== firebaseInfo.id)) && (
          <>
            <form className="message-sender-form" onSubmit={handleSendChatMessage}>
              <input value={newMessage} onChange={handleNewMessageChange} placeholder="Escribe tu mensaje aquí..." />
            </form>
            <FontAwesomeIcon className="message-send-icon clickable" icon={faFileUpload} size="lg" onClick={() => setShowPreviousFilesDialog(true)} />
            <FontAwesomeIcon className="message-send-icon clickable" icon={faPaperPlane} size="lg" onClick={handleSendChatMessage} />
          </>
        )}
        {!chatUtils.isAdmin(userRole) && !(selectedConversation.chatMode
          || selectedConversation.createdBy !== firebaseInfo.id) && (
          <p className="message-sender-disabled">
            A la espera de respuesta
          </p>
        )}
        {chatUtils.isAdmin(userRole) && (
          <p className="message-sender-disabled">
            El administrador no puede participar
          </p>
        )}
      </div>
      {showPreviousFilesDialog && (
        <SimpleFileLoader
          memberId={firebaseInfo.id}
          callbackWithFile={handleSelectedFile}
        />
      )}
    </div>
  );
};

ConversationV2.propTypes = {
  chatId: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  fitParentHeight: PropTypes.bool,
  refresh: PropTypes.func,
}.isRequired;

ConversationV2.defaultValues = {
  fitParentHeight: false,
  refresh: null,
};

export default ConversationV2;
