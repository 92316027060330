/* eslint-disable max-len */
import React from 'react';
import FamilyInitialContainer from 'components/containers/FamilyInitialContainer';
import {
  Container, Row, Col, Nav,
} from 'react-bootstrap';

import style from './styles.module.css';

export default function FamilyDashBoard() {
  // const vacantestURL = getUrl('vacantest');
  // const title = 'POSTULA EN BUSCA DE OPORTUNIDADES';
  const title = 'Encontrá el centro educativo ideal para tus hijos';
  // eslint-disable-next-line max-len
  // const intro = 'Bienvenid@ a tu Área Privada de Familia, podrás analizar y gestionar todas las instituciones educativas recomendadas en los resultados generados en tus Búsquedas Inteligentes y en el Test de Compatibilidad. Desde aquí podrás evaluar las mejores opciones de centros disponibles para tus hijos y marcarlos como favoritos. Luego, podrás Postular a las Instituciones seleccionadas.';
  const intro = 'Realizá nuestro test de compatibilidad para comenzar con una pre‑selección inteligente.';

  return (
    <Container className={`${style.container}`}>
      <Col className={`${style.title}`}>
        <h3>{title}</h3>
      </Col>
      <Col className={`${style.intro}`}>
        <p>{intro}</p>
      </Col>
      <Row className={style.row}>
        <Nav className={style.nav}>
          {/* <FamilyInitialContainer path="favorites">MIS FAVORITOS </FamilyInitialContainer> */}
          <FamilyInitialContainer path="new_test">NUEVO TEST</FamilyInitialContainer>
          <FamilyInitialContainer path="vacantests">TESTS REALIZADOS</FamilyInitialContainer>
          <FamilyInitialContainer path="messages">MENSAJES CON CENTROS EDUCATIVOS</FamilyInitialContainer>
          {/* <FamilyInitialContainer path="busquedas">MIS BÚSQUEDAS </FamilyInitialContainer> */}
        </Nav>
      </Row>
    </Container>
  );
}
